<template>
  <div>
    <section
      class="hero is-medium is-bold"
      style="
        background-image: url('/img/banner.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
      "
    >
      <div class="hero-head">
        <b-navbar
          type="is-black"
          style="z-index: 2001; background-color: transparent"
        >
          <template slot="brand">
            <b-navbar-item tag="router-link" :to="{ path: '/' }">
              <img src="@/assets/logopng.png" :alt="appInfo.name" />
              <span
                class="title is-5 is-uppercase has-text-white"
                style="margin-left: 10px; text-shadow: 2px 2px 5px orange"
                >{{ appInfo.name }}</span
              >
            </b-navbar-item>
          </template>
          <template slot="end">
            <b-navbar-dropdown>
              <template slot="label">
                <b-icon
                  class="has-text-primary"
                  style="
                    margin-left: 10px;
                    margin-right: 10px;
                    text-shadow: 2px 2px 5px orange;
                  "
                  icon="map"
                />Ventures
              </template>
              <b-navbar-item>
                <b-icon
                  class="has-text-primary"
                  style="
                    margin-left: 10px;
                    margin-right: 10px;
                    text-shadow: 2px 2px 5px orange;
                  "
                  icon="map-marker"
                />
                Sri Sai Balaji Township
              </b-navbar-item>
            </b-navbar-dropdown>
            <b-navbar-item href="#videos">
              <b-icon
                class="has-text-primary"
                style="
                  margin-left: 10px;
                  margin-right: 10px;
                  text-shadow: 2px 2px 5px orange;
                "
                icon="video"
              />Videos
            </b-navbar-item>
            <b-navbar-item href="#layout">
              <b-icon
                class="has-text-primary"
                style="
                  margin-left: 10px;
                  margin-right: 10px;
                  text-shadow: 2px 2px 5px orange;
                "
                icon="rectangle-outline"
              />Interactive Layout
            </b-navbar-item>
            <b-navbar-item href="#highlights">
              <b-icon
                class="has-text-primary"
                style="
                  margin-left: 10px;
                  margin-right: 10px;
                  text-shadow: 2px 2px 5px orange;
                "
                icon="auto-fix"
              />Highlights
            </b-navbar-item>
            <b-navbar-item href="#photos">
              <b-icon
                class="has-text-primary"
                style="
                  margin-left: 10px;
                  margin-right: 10px;
                  text-shadow: 2px 2px 5px orange;
                "
                icon="image-multiple"
              />Photos
            </b-navbar-item>
            <b-navbar-item href="#news">
              <b-icon
                class="has-text-primary"
                style="
                  margin-left: 10px;
                  margin-right: 10px;
                  text-shadow: 2px 2px 5px orange;
                "
                icon="newspaper"
              />News
            </b-navbar-item>
            <b-navbar-item href="#contact">
              <b-icon
                class="has-text-primary"
                style="
                  margin-left: 10px;
                  margin-right: 10px;
                  text-shadow: 2px 2px 5px orange;
                "
                icon="phone"
              />Contact
            </b-navbar-item>
          </template>
        </b-navbar>
      </div>
      <div class="hero-body">
        <div
          class="container has-text-centered"
          style="min-height: 250px"
        ></div>
      </div>
    </section>
    <div class="container-fluid">
      <div class="container" style="margin-top: 20px">
        <h1 id="videos" class="is-size-5">
          <span class="icon is-small" style="margin-right: 5px; color: #ff8000"
            ><i class="mdi mdi-video"></i
          ></span>
          Videos
        </h1>
        <figure class="image is-3by2">
          <iframe
            class="has-ratio"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/an19DU6xaWM"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </figure>
      </div>
    </div>
    <div class="container is-fluid">
      <!-- <%= BASE_URL %> -->
      <div class="container" style="margin-top: 20px">
        <h1 id="layout" class="is-size-5">
          <span class="icon is-small" style="margin-right: 5px; color: #ff8000"
            ><i class="mdi mdi-rectangle-outline"></i
          ></span>
          Interactive Layout
        </h1>
        <embed
          ref="mapFrame"
          key="NavJoljnCD8S"
          type="text/html"
          :src="url"
          width="100%"
          height="419"
          style="border: 1px solid green"
          allowfullscreen
        />
      </div>
      <div class="container">
        <h1 id="highlights" class="is-size-5">
          <span class="icon is-small" style="margin-right: 5px; color: #ff8000"
            ><i class="mdi mdi-auto-fix"></i
          ></span>
          Highlights
        </h1>
        <hr class="is-marginless" />
        <div style="margin-top: 15px">
          <h1 class="title is-6">
            <span class="icon is-small" style="color: #ff8000"
              ><i class="mdi mdi-alpha-l-circle"></i
            ></span>
            Layout Permission Number
          </h1>
          <h2 class="content subtitle is-6">000361/LO/plg/HMDA/2019</h2>
        </div>
        <div style="margin-top: 15px">
          <h1 class="title is-6">
            <span class="icon is-small" style="color: #ff8000"
              ><i class="mdi mdi-alpha-t-circle"></i
            ></span>
            TS RERA No
          </h1>
          <h2 class="content subtitle is-6">P02000002087</h2>
        </div>
        <div style="margin-top: 15px">
          <div class="level">
            <div class="level-item level-left">
              <div class="content">
                <h1 class="title is-6">
                  <span class="icon is-small" style="color: #ff8000"
                    ><i class="mdi mdi-alpha-v-circle"></i
                  ></span>
                  Venture Features
                </h1>
                <ul>
                  <li>HMDA approved layout</li>
                  <li>Vaasthu &amp; Clear Title</li>
                  <li>60,50 &amp; 33 feet black top roads</li>
                  <li>Underground Drainage</li>
                  <li>Overhead water tank</li>
                  <li>Parks with landscaping</li>
                  <li>Avenue Plantation</li>
                  <li>Modern Modular street lights</li>
                  <li>Electrical lines &amp; Water pipes on all roads</li>
                </ul>
              </div>
            </div>
            <div class="level-item level-left">
              <div class="content">
                <h1 class="title is-6">
                  <span class="icon is-small" style="color: #ff8000"
                    ><i class="mdi mdi-alpha-p-circle"></i
                  ></span>
                  Project Highlights
                </h1>
                <ul>
                  <li>Very Near to Ramoji Film City</li>
                  <li>10 Min. Drive from Outer Ring Road</li>
                  <li>Near by to many Educational Institutions</li>
                  <li>Drive away distance to Sanghi Temple</li>
                  <li>Opposite to TSIIDC SEZ</li>
                  <li>Near by Truck Parking</li>
                  <li>5 minutes drive to Mount Opera</li>
                  <li>Upcoming Many Software Companies</li>
                  <li>Nearby residential School &amp; Engineering Colleges</li>
                  <li>
                    Nearby famous pochampally sarees in pochampally village.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="photos" class="container" style="margin-top: 25px">
        <p class="title has-text-primary is-4 is-marginless">
          <b-icon icon="image-multiple" /> Photos
        </p>
        <hr class="is-marginless is-paddingless" />
        <div class="columns">
          <div class="column is-half">
            <img v-img src="/img/broucher1.jpeg" alt="Broucher-1" />
          </div>
          <div class="column is-half">
            <img v-img src="/img/broucher2.jpeg" alt="Broucher-2" />
          </div>
        </div>
      </div>
      <div id="news" class="container" style="margin-top: 25px">
        <p class="title has-text-primary is-4 is-marginless">
          <b-icon icon="newspaper" /> News
        </p>
        <hr class="is-marginless is-paddingless" />
        <div class="columns">
          <div class="column is-half">
            <img v-img src="/img/cutout-01.jpeg" alt="Andhra Jyothi Clip" />
          </div>
          <div class="column is-half">
            <img v-img src="/img/cutout-02.png" alt="Shakshi Clip" />
          </div>
        </div>
      </div>
    </div>
    <footer class="footer" id="contact">
      <div class="section-heading">
        <div class="columns">
          <div class="column">
            <div class="level">
              <div class="level-item has-text-centered">
                <img
                  style="max-height: 128px"
                  src="@/assets/logopng.png"
                  :alt="appInfo.name"
                />
              </div>
              <div class="level-item">
                <p class="">
                  <strong class="title is-4 has-text-primary">{{
                    appInfo.name
                  }}</strong>
                  <br />
                  <span v-html="address"></span>
                </p>
              </div>
              <div class="level-item">
                <p class="has-text-centered">
                  <strong class="title is-4 has-text-primary"
                    >Sales and Enquiry</strong
                  >
                  <br />
                  <a class="is-size-4" href="tel:9030987589">9030987589</a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <p class="has-text-centered">
          All rights reserved
          <strong>{{ appInfo.name }}</strong>
          © 2019 - {{ new Date().getFullYear() }}
        </p>
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  name: "home",
  data() {
    return {
      appInfo: {},
    };
  },
  mounted() {
    window.sessionStorage.clear();
    this.$refs.mapFrame.src = this.url;
  },
  methods: {
    getHtml(value) {
      if (value) return value.replace(/\n/g, "<br />");
      return "";
    },
  },
  filters: {
    toHtml: function (value) {
      if (value) return value.replace(/\n/g, "<br />");
      return "";
    },
  },
  computed: {
    address: function () {
      if (this.appInfo.address)
        return this.appInfo.address.replace(/\n/g, "<br />");
      return "";
    },
    url() {
      return `https://p99.in/map/NavJoljnCD8S`;
    },
  },
  async created() {
    if (sessionStorage.getItem("appInfo"))
      this.appInfo = JSON.parse(sessionStorage.getItem("appInfo"));
  },
};
</script>
<style lang="scss">
.navbar.is-black .navbar-brand > a.navbar-item:focus,
.navbar.is-black .navbar-brand > a.navbar-item:hover,
.navbar.is-black .navbar-brand > a.navbar-item.is-active,
.navbar.is-black .navbar-brand .navbar-link:focus,
.navbar.is-black .navbar-brand .navbar-link:hover,
.navbar.is-black .navbar-brand .navbar-link.is-active {
  background-color: lightslategray;
  color: white;
}
.navbar.is-black .navbar-item.has-dropdown:focus .navbar-link,
.navbar.is-black .navbar-item.has-dropdown:hover .navbar-link,
.navbar.is-black .navbar-item.has-dropdown.is-active .navbar-link {
  background-color: lightslategray;
  color: white;
}
.navbar.is-black .navbar-start > a.navbar-item:focus,
.navbar.is-black .navbar-start > a.navbar-item:hover,
.navbar.is-black .navbar-start > a.navbar-item.is-active,
.navbar.is-black .navbar-start .navbar-link:focus,
.navbar.is-black .navbar-start .navbar-link:hover,
.navbar.is-black .navbar-start .navbar-link.is-active,
.navbar.is-black .navbar-end > a.navbar-item:focus,
.navbar.is-black .navbar-end > a.navbar-item:hover,
.navbar.is-black .navbar-end > a.navbar-item.is-active,
.navbar.is-black .navbar-end .navbar-link:focus,
.navbar.is-black .navbar-end .navbar-link:hover,
.navbar.is-black .navbar-end .navbar-link.is-active {
  background-color: lightslategray;
  color: white;
}
</style>