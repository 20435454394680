import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Buefy from 'buefy'
import VueMeta from 'vue-meta'
import VueImg from 'v-img';
Vue.use(VueImg);
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});
Vue.use(Buefy);
Vue.config.productionTip = false
new Vue({
  router,
  render: h => h(App)
}).$mount('#app');