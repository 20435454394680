import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      guest: true
    }
  },
  {
    path: '*',
    redirect: '/'
  }
]
let router = new VueRouter({
  mode:"history",
  routes:routes
})
export default router
