<template>
  <div id="app">
    <keep-alive>
      <router-view />
    </keep-alive>
  </div>
</template>
<script>
import siteInfo from '@/assets/app.json'
export default {
  metaInfo() {
    return {
      title: siteInfo.name,
      titleTemplate: "%s",
      meta: siteInfo.metaTags,
      link: [
        { rel: "icon", href: "/favicon.ico" },
        {
          rel: "shortcut icon",
          href: "/favicon.ico"
        },
        {
          rel: "apple-touch-icon",
          href: "/favicon.ico"
        },
        {
          rel: "apple-touch-icon-precomposed",
          href: "/favicon.ico"
        }
      ]
    };
  },
  async created() {
    sessionStorage.setItem("appInfo", JSON.stringify(siteInfo));
  }
};
</script>
<style lang="scss">
// Import Bulma's core
@import "~bulma/sass/utilities/_all";
// Set your colors
$primary: #ff7f2a;
$primary-invert: findColorInvert($primary);
$twitter: gray;
$twitter-invert: findColorInvert($twitter);

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: (
  "white": (
    $white,
    $black
  ),
  "black": (
    $black,
    $white
  ),
  "light": (
    $light,
    $light-invert
  ),
  "dark": (
    $dark,
    $dark-invert
  ),
  "primary": (
    $primary,
    $primary-invert
  ),
  "info": (
    $info,
    $info-invert
  ),
  "success": (
    $success,
    $success-invert
  ),
  "warning": (
    $warning,
    $warning-invert
  ),
  "danger": (
    $danger,
    $danger-invert
  ),
  "twitter": (
    $twitter,
    $twitter-invert
  )
);
// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";
</style>
